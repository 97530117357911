import React from 'react';

import {Grid, Paper, styled} from "@mui/material";

const Card = styled(Paper)(({theme}: any) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 300,
    width: 300,
    lineHeight: '60px',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#23252cAA',
        transition: 'all 0.2s'
    }
}));

export default function AppsCards() {
    return (
        <Grid container xs={12} spacing={2}>
            <Grid item>
                <Card onClick={_ => window.open('https://ai-apps.leftshiftone.com/hera')}>
                    H.E.R.A.
                </Card>
            </Grid>
            <Grid item>
                <Card onClick={_ => window.open('https://ai-apps.leftshiftone.com/')}>
                    K.L.A.A.S.
                </Card>
            </Grid>
        </Grid>
    )
}
